import $ from "jquery";

import naja from 'naja';
import netteForms from 'nette-forms';

window.naja = naja;
window.Nette = netteForms;
window.jQuery = jQuery;

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));
netteForms.initOnLoad();

window.naja.uiHandler.addEventListener('interaction', (event) => {
    const {element} = event.detail;
    if (element.dataset === 'undefined') {
        return;
    }

    const question = element.dataset.confirm;
    if (question && !window.confirm(question)) {
        event.preventDefault();
    }
});

// import bootstrap from 'bootstrap'
import bootstrap from 'bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css';


// Chart.js
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';

window.Chart = Chart;

// ---------------------------------------------------------------------------

// Sortable
$(function () {
    $(".sortable").sortable();
});


// Sticky tabs
$(function () {

    var hash = window.location.hash;
    hash && $('ul.nav.sticky a[href="' + hash + '"]').tab('show');

    $('.nav-tabs.sticky a, .nav-pills.sticky a').click(function (e) {
        $(this).tab('show');
        var scrollmem = $('body').scrollTop();
        window.location.hash = this.hash;
        $('html,body').scrollTop(scrollmem);
    });

    $(window).on('hashchange', function (e) {
        var hash = window.location.hash;
        hash && $('ul.nav.sticky a[href="' + hash + '"]').tab('show');
    });

    $(document).on('DOMNodeInserted', '.nav.sticky', function (e) {
        let hash = window.location.hash;
        let a = $(document).find('a[href="' + hash + '"]').eq(0);
        $(a).addClass('active');
    });

});

// jQuery Slider
import 'jquery-ui';
import '../../node_modules/jquery-ui/themes/base/core.css';
import '../../node_modules/jquery-ui/themes/base/theme.css';
import '../../node_modules/jquery-ui/themes/base/slider.css';

$(function () {

    $("input[data-type=multiple-range-slider],input[data-type=range-slider]").each(function (input) {

        var dataType = $(this).attr('data-type');
        var multipleSlider = (dataType === 'multiple-range-slider');

        var input = $(this);
        var inputValue = input.val();
        var sliderDiv = $(this).parents('div').eq(0).find('div.slider-range').eq(0);
        var sliderInfoDiv = $(this).siblings('div.slider-range-info').eq(0);
        var sliderHandles = sliderDiv.find('.ui-slider-handle');

        var lowerLimit = input.attr('data-lower-limit') > 0 ? input.attr('data-lower-limit') : 0;
        var higherLimit = input.attr('data-higher-limit') > 0 ? input.attr('data-higher-limit') : 0;

        var defaultValues = prepareValues(inputValue, lowerLimit, higherLimit);
        var defaultValue = prepareValue(inputValue, lowerLimit);

        sliderDiv.slider({
            range: multipleSlider,
            min: parseInt(lowerLimit),
            max: parseInt(higherLimit),
            'value': defaultValue,
            'values': defaultValues,
            animate: "fast",
            slide: function (event, ui) {
                setValueAndShowInfo(multipleSlider, ui.value, ui.values, input, sliderHandles);
            },
            create: function (event, ui) {
                input.hide();
            }
        });

        let values = [
            sliderDiv.slider("values", 0),
            sliderDiv.slider("values", 1),
        ];
        let value = sliderDiv.slider("value");
        setValueAndShowInfo(multipleSlider, value, values, input, sliderHandles);

        function prepareValues(inputValue, lowerLimit, higherLimit) {
            if (inputValue === '') {
                var defaultValues = [lowerLimit, higherLimit];
            } else {
                let value = inputValue.split('-');
                var defaultValues = [value[0], value[1]];
            }
            return defaultValues;
        }

        function prepareValue(inputValue, lowerLimit) {
            return inputValue || lowerLimit;
        }

        function setValueAndShowInfo(multipleSlider, value, values, input, sliderHandles) {

            let useCustomHandles = input.attr('data-custom-handles');

            if (multipleSlider) {
                let val = values[0] + '-' + values[1];
                input.val(val);
                input.attr('value', val);
                sliderInfoDiv.html(val);
                if (useCustomHandles) {
                    sliderHandles.eq(0).text(values[ 0 ]);
                    sliderHandles.eq(1).text(values[ 1 ]);
                }
            } else {
                input.val(value);
                input.attr('value', value);
                sliderInfoDiv.html(value);
                if (useCustomHandles) {
                    sliderHandles.eq(0).text(value);
                }
            }
        }
    });
});

$(function () {

    $("select[data-type=range-slider]").each(function (element) {
        var select = $(this);
        var selectId = select.attr('id');
        var sliderId = selectId + '_slider';

        var values = select.find("option").map(function () {
            return this.value;
        }).get();

        let max = Math.max(...values);
        let min = Math.min(...values);

        var selectedIndex = select.prop('selectedIndex');
        var slider = $("<div id='" + sliderId + "'></div>").insertAfter(select).slider({
            min: 0,
            max: values.length - 1,
            range: "min",
            value: selectedIndex,
            slide: function (event, ui) {
                select.prop('selectedIndex', ui.value);
                selectedIndex = ui.value;
            }
        });
        $(select).on("change", function () {
            slider.slider("value", this.selectedIndex);
        });
    });

});


// Lightbox
import lightbox from 'lightbox2/dist/js/lightbox';
import '../../node_modules/lightbox2/dist/css/lightbox.min.css';
lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true
});

// Custom form validators
Nette.validators['CoolCMSFormsValidatorsCustom_allIn'] = (elem, args, val) => {
    for (let i = 0; i < args.length; i++) {
        if (!val.includes(args[i].toString())) {
            return false;
        }
    }
    return true;
};

//LightSlider and LightGallery
import lightSlider from 'lightslider';
import "../../node_modules/lightslider/dist/css/lightslider.css";

import lightGallery from 'lightgallery';
// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'

import "../../node_modules/lightgallery/css/lightgallery-bundle.min.css";
import "lightgallery/css/lightgallery-bundle.min.css";

window.onpopstate = function () {

    if (lastOpenedLigthGallery !== null) {
        window.history.pushState(null, "", window.location.href);
        lastOpenedLigthGallery.closeGallery();
        lastOpenedLigthGallery = null;
    }
};

document.addEventListener("DOMContentLoaded", function(){
    
    let lightSliderElms = document.getElementsByClassName("lightSlider");
    for (let i = 0; i < lightSliderElms.length; i++) {
        setUpLightSlider(lightSliderElms[i]);
    }

    let lightGalleryElms = document.getElementsByClassName("lightgallery");
    for (let i = 0; i < lightGalleryElms.length; i++) {
        if(lightGalleryElms[i].classList.contains('lightSlider')) {
            continue;        
        }     
        setUpLightGallery(lightGalleryElms[i]);
    }

    naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        const { snippet } = event.detail;

        const lightGalleryElms = snippet.getElementsByClassName("lightgallery");
        for (let i = 0; i < lightGalleryElms.length; i++) {
            setUpLightGallery(lightGalleryElms[i]);
        }
    });
});

var lastOpenedLigthGallery = null;
var lightGalleryIsOpen = false;

function setUpLightGallery(element, selector = 'a') {
    let gallery = lightGallery(element, {
        selector: selector,
        plugins: [lgZoom, lgThumbnail, lgVideo],
        swipeToClose: true,
        closeOnTap: true,
        escKey: true,
        speed: 500,
        licenseKey: '0000-0000-000-0000',
        videojs: true,
        videojsOptions: {
            muted: true,
        },
        mobileSettings: {
            controls: true,
            showCloseIcon: true,
            download: false
        }
    });

    element.addEventListener('lgBeforeOpen', (event) => {
        window.history.pushState(null, "", window.location.href);
        lastOpenedLigthGallery = gallery;
    });

    element.addEventListener('lgBeforeClose', (event) => {
        lastOpenedLigthGallery = null;
        window.history.back();
    });
}

function setUpLightSlider(sliderElement, selector = '#lightgallery .lslide') {

    $(sliderElement).lightSlider({
        gallery: true,
        item: 1,
        auto: false,
        loop: true,
        pauseOnHover: true,
        slideMove: 1,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed: 600,
        enableDrag: false,
        currentPagerPosition: 'middle',
        onSliderLoad: function (el) {
            setUpLightGallery(sliderElement, '.lslide');
        }
    });
}